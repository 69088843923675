import React, {
  useState,
  useEffect
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStepCount, incrementStep } from "../store/stepCount";
import { fetchTargetSchema } from "../store/targetSchemaSlice";
import { setObjectData } from "../store/customMappingStore";
import StepIndicator from "./StepIndicator";
import CircularProgress from "@mui/material/CircularProgress";

import CustomNavbar from './CustomNavbar'

import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { sampleFHIRR4Patient, sampleDCSABooking } from "./SampleTargetJson";

import { GET_TEMPLATE_STANDARDS } from '../constants/schemaStandard';

import { setSourceAndTargetSchema } from "../store/schema";

function ImportData() {
  const currentStep = useSelector(getStepCount);
  const [sourceSchema, setSourceSchema] = useState(GET_TEMPLATE_STANDARDS[0].API_KEY);
  const [targetStandard, setTargetStandard] = useState(GET_TEMPLATE_STANDARDS[0].MODELS[0].API_KEY);
  const [targetStandardModels, setTargetStandardModels] = useState(GET_TEMPLATE_STANDARDS[0].MODELS);
  const dispatch = useDispatch();
  const targetSchema = useSelector((state) => state.targetSchema.targetSchema);
  const targetSchemaState = useSelector((state) => state.targetSchema.status);

  useEffect(() => {
    setTargetStandard();
  }, [sourceSchema]);

  function handleNext() {
    if(targetSchema) {
      const completeSourceSchema = GET_TEMPLATE_STANDARDS.find((eachTemplateStandard) => eachTemplateStandard.API_KEY === sourceSchema);
      dispatch(setSourceAndTargetSchema({ sourceSchema: sourceSchema, targetSchema: targetStandard, clientId: completeSourceSchema?.CLIENT_API_KEY }));

      dispatch(setObjectData(JSON.stringify(targetSchema)));
      dispatch(incrementStep());
    } else {
      alert("Please select a target schema");
    }
  }

  const onSourceSchemaChange = (selectedSchema) => {
    setSourceSchema(selectedSchema);

     // Find the corresponding MODELS for the selected Source Schema
    const selectedData = GET_TEMPLATE_STANDARDS.find(
      (item) => item.API_KEY === selectedSchema
    );

    setTargetStandardModels(selectedData ? selectedData.MODELS : GET_TEMPLATE_STANDARDS[0].MODELS);
  };

  const onTargetSchemaChange = (targetStandard) => {
    setTargetStandard(targetStandard)

    dispatch(fetchTargetSchema({ sourceSchema, targetStandard }));
  }

  return (
    <>
    <CustomNavbar />
    <Paper elevation={1} sx={{ p: 3, maxWidth: "80vw", mx: "auto" }}>
      <StepIndicator activeStep={currentStep} />
      <Box sx={{ textAlign: "left", mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Select Source Schema and Target Standard
        </Typography>
        <Typography variant="body1">
          Make changes to the mapping of columns.
        </Typography>
      </Box>

      <Divider sx={{ mb: 4 }} />

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>

      <FormControl fullWidth>
        <InputLabel id="source-schema-label">Source Schema</InputLabel>
        <Select
          labelId="source-schema-label"
          id="source-schema"
          value={sourceSchema}
          label="Source Schema"
          onChange={(e) => onSourceSchemaChange(e.target.value)}>

          { GET_TEMPLATE_STANDARDS.map(eachTemplateStandard => {
              return (
                <MenuItem key={eachTemplateStandard.API_KEY} value={eachTemplateStandard.API_KEY}>{eachTemplateStandard.CLIENT_DISPLAY_SCHEME_NAME}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl> 

      <FormControl fullWidth>
        <InputLabel id="target-standard-label">Target Standard</InputLabel>
        <Select
          labelId="target-standard-label"
          id="target-standard"
          value={targetStandard}
          label="Target Standard"
          onChange={(e) => onTargetSchemaChange(e.target.value)}>

          { targetStandardModels && 
            targetStandardModels.map(templateModels => {
              return (
                <MenuItem key={templateModels.API_KEY} value={templateModels.API_KEY}>{templateModels.DISPLAY_NAME}</MenuItem>
              )
            })
          }
        </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title={targetStandard ? "" : "Select Target Standard"}>
          <div>
            <Button variant="contained" onClick={handleNext} disabled={ targetSchemaState !== "succeeded" }>
              Next
            </Button>
          </div>
        </Tooltip>
      </Box>

      { targetSchemaState === "loading" && <CircularProgress /> }

      {/* { targetSchema && 
        <Box sx={{ display: "flex" }}>
          <pre className="display-json">
            { JSON.stringify(targetSchema, null, 2) }
          </pre>
        </Box> 
      } */}

    </Paper>
    </>
  );
}

export default ImportData;
