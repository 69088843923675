import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const generateRules = createAsyncThunk(
  'rules/generateRules',
  async (requestParams, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAPPER_ENGINE_BASE_URL}/chat/generate-rules`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestParams),
      });
      if (!response.ok) {
        throw new Error('An unexpected error occured while generating rules for the target schema');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const generateRulesSlice = createSlice({
  name: 'rules',
  initialState: {
    rules: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.rules = action.payload.data.rules;
      })
      .addCase(generateRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default generateRulesSlice.reducer;
