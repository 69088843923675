import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const generateMapping = createAsyncThunk(
  'mapping/generateMapping',
  async (requestParams, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MAPPER_ENGINE_BASE_URL}/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestParams),
      });
      if (!response.ok) {
        throw new Error('An unexpected error occured while generating mapping for the target schema');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const generateMappingSlice = createSlice({
  name: 'mapping',
  initialState: {
    mappingJson: null,
    lookupJson: null,
    queriesJson: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    setNewMapping: (state, action) => {
      state.mappingJson = action.payload;
    },
    setNewLookup: (state, action) => {
      state.lookupJson = action.payload;
    },
    setNewQueries: (state, action) => {
      state.queriesJson = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateMapping.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateMapping.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.mappingJson = action.payload.data.mapping;
        state.queriesJson = action.payload.data.lookup.queries;

        const lookupJsonWithoutQueries = { ...action.payload.data.lookup };
        delete lookupJsonWithoutQueries?.queries;

        state.lookupJson = lookupJsonWithoutQueries;
      })
      .addCase(generateMapping.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setNewMapping, setNewLookup, setNewQueries } = generateMappingSlice.actions;

export default generateMappingSlice.reducer;
