import React
, { useEffect,
useState }  // {useState, useEffect }
  from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStepCount, decrementStep } from "../store/stepCount";
import StepIndicator from "./StepIndicator";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import { sampleRule } from "./SampleTargetJson";
import { generateRules } from "../store/generateRulesSlice";
import { storeRules } from "../store/storeRulesSlice";
import { useNavigate } from "react-router-dom";

import MagicWandIcon from '../magic-wand.png';
import CustomNavbar from "./CustomNavbar";
import { fetchExistingRules } from "../store/getExistingRulesSlice";

function ReviewAndConfirmRules() {
  const [rules, setRules] = useState("");
  const [loader, setLoader] = useState(false);

  const currentStep = useSelector(getStepCount);
  const sourceAndTargetSchema = useSelector((state) => state.schema);

  const generatedRules = useSelector((state) => state?.rules.rules);
  const existingRules = useSelector((state) => state?.existingRules?.rules);

  const generateRulesState = useSelector((state) => state?.rules.status);

  const storeRulesState = useSelector((state) => state?.storeRules.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    if (generateRulesState === "succeeded") {
      setLoader(false);
    } else if (generateRulesState === "failed") {
      setLoader(false);
    }
  }, [generateRulesState]);

  useEffect(() => {
    if (storeRulesState === "succeeded") {
      setLoader(false);
    } else if (storeRulesState === "failed") {
      setLoader(false);
    }
  }, [storeRulesState])


  useEffect(() => {
    if(generatedRules) {
      setRules(generatedRules);
    }
  }, [generatedRules])

  useEffect(() => {
    if(existingRules) {
      setLoader(false);
      setRules(existingRules);
    }
  }, [existingRules])

  function handleBack() {
    dispatch(decrementStep());
  }

  const generateRulesWithAI = () => {
    setLoader(true);

    const requestBody = {
      "message": `Generate ${sourceAndTargetSchema.targetSchema} Resource`,
      "targetSchema": sourceAndTargetSchema.targetSchema,
      "clientId": sourceAndTargetSchema.clientId
    }
    dispatch(generateRules(requestBody));
  }

  const fetchGeneratedRules = () => {
    setLoader(true);
    dispatch(fetchExistingRules({ clientApiKey: sourceAndTargetSchema.clientId, targetStandard: sourceAndTargetSchema.targetSchema }));
  }

  const storeRulesToBackend = () => {
    setLoader(true);

    const blob = new Blob([rules], { type: "text/plain" });

    const formData = new FormData();
    formData.append("file", blob, "rules.drl");

    dispatch(storeRules({ clientApiKey: sourceAndTargetSchema.clientId, targetStandard: sourceAndTargetSchema.targetSchema, formData: formData, navigate, dispatch }));
  }

  return (
    <>
      <CustomNavbar />
      <Paper elevation={1} sx={{ p: 3, maxWidth: "75vw", mx: "auto" }}>
        <StepIndicator activeStep={currentStep} />
        {loader && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to freeze the page
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <div className="custom-mapping-table">
          <div className="header">
            <h2>Confirm</h2>
            <p>Review Rules for Transformation</p>
          </div>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", paddingTop: '20px', paddingBottom: '20px' }}>
            <Button variant="outlined" onClick={generateRulesWithAI}>
              Generate Rules With AI &nbsp; <img src={MagicWandIcon} alt="Magic Wand" style={{ width: "18px" }}/>
            </Button>

            <Button variant="outlined" onClick={fetchGeneratedRules}>
              Retrieve AI Generated Rules &nbsp; 
            </Button>
          </Box>


          <div style={{ width: '100%', height: '800px', display: 'flex' }}>
            <textarea style={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box', // Ensure padding and border are included in the element's width and height
              }}
              value={rules}
              onChange={(e) => setRules(e.target.value)}
            />
          </div>

        </div>

        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", paddingTop: '20px' }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={storeRulesToBackend}>
            Submit
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default ReviewAndConfirmRules;
