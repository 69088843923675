import { createSlice } from '@reduxjs/toolkit';

const conversionStandard = createSlice({
  name: 'conversionStandarad', // Name of the slice
  initialState: { // Initial state of the slice
    target: "FHIR R4 - patient", 
}, 
  reducers: {
    setConversionTarget: (state, action) => {
      state.target = action.payload;
    },
  },
});

export const getConversionStandard = ({ conversionStandard }) => conversionStandard.target;

export const { setConversionTarget } = conversionStandard.actions;
export default conversionStandard.reducer;
