import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setStep } from './stepCount';

// Define an async thunk for fetching data
export const storeRules = createAsyncThunk(
  'rules/storeRules',
  async ({ clientApiKey, targetStandard, formData, navigate, dispatch }, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/rules/client/${clientApiKey}/model/${targetStandard}`,
        {
            method: 'POST',
            body: formData
        }
      );
      if (!response.ok) {
        throw new Error('An unexpected error occured while storing the mapping');
      }
      dispatch(setStep(-1));
      navigate("/results")
      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const storeRulesSlice = createSlice({
  name: 'storeRules',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storeRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(storeRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default storeRulesSlice.reducer;
