import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const transformEngine = createAsyncThunk(
  'transform/transformEngine',
  async (targetStandard, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/transform-cdc/${targetStandard}.json`, 
        {
          method: 'post'
        }
      );
      if (!response.ok) {
        throw new Error('An unexpected error occured while transforming the cdc input into target schema');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const transformEngineSlice = createSlice({
  name: 'transformEngine',
  initialState: {
    transformedData: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(transformEngine.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(transformEngine.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transformedData = action.payload.transformedData;
      })
      .addCase(transformEngine.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default transformEngineSlice.reducer;
