import React from 'react';

import { Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material"
import { GET_TEMPLATE_STANDARDS } from "../constants/schemaStandard"
import { transformEngine } from "../store/transformEngineSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import RenderTreeTable from './RenderTreeTable';
import CustomNavbar from './CustomNavbar';


function FinalResults() {

    const [loader, setLoader] = useState(false);
    const [sourceSchema, setSourceSchema] = useState(GET_TEMPLATE_STANDARDS[0].API_KEY);
    const [targetStandard, setTargetStandard] = useState(GET_TEMPLATE_STANDARDS[0].MODELS[0].API_KEY);
    const [targetStandardModels, setTargetStandardModels] = useState(GET_TEMPLATE_STANDARDS[0].MODELS);

    const transformedData = useSelector((state) => state?.transformEngine?.transformedData);
    const transformedDataState = useSelector((state) => state?.transformEngine?.status);

    const dispatch = useDispatch();

    useEffect(() => {
        if(transformedDataState === "succeeded") {
            setLoader(false);
        } else if(transformedDataState === "failed") {
            setLoader(false);
        }
    }, [transformedDataState])

    const onSourceSchemaChange = (selectedSchema) => {
        setSourceSchema(selectedSchema);
    
         // Find the corresponding MODELS for the selected Source Schema
        const selectedData = GET_TEMPLATE_STANDARDS.find(
          (item) => item.API_KEY === selectedSchema
        );
    
        setTargetStandardModels(selectedData ? selectedData.MODELS : GET_TEMPLATE_STANDARDS[0].MODELS);
    };

    const onTargetSchemaChange = (targetStandard) => {
        setTargetStandard(targetStandard)    
    }

    const showTransformedData = () => {
        setLoader(true);
        dispatch(transformEngine(targetStandard));
    }

    return (<>

        <CustomNavbar />
        <Paper elevation={1} sx={{ p: 3, maxWidth: "80vw", mx: "auto" }}>
            {loader && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to freeze the page
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                    >
                    <CircularProgress />
                </Box>
            )}

            <Box sx={{ display: "flex", gap: 2, mt: 5, mx: 10, pb: 10 }}>
                <FormControl fullWidth>
                    <InputLabel id="source-schema-label">Source Schema</InputLabel>
                    <Select
                    labelId="source-schema-label"
                    id="source-schema"
                    value={sourceSchema}
                    label="Source Schema"
                    onChange={(e) => onSourceSchemaChange(e.target.value)}>

                    { GET_TEMPLATE_STANDARDS.map(eachTemplateStandard => {
                        return (
                            <MenuItem key={eachTemplateStandard.API_KEY} value={eachTemplateStandard.API_KEY}>{eachTemplateStandard.CLIENT_DISPLAY_SCHEME_NAME}</MenuItem>
                        )
                        })
                    }
                    </Select>
                </FormControl> 

                <FormControl fullWidth>
                    <InputLabel id="target-standard-label">Target Standard</InputLabel>
                    <Select
                    labelId="target-standard-label"
                    id="target-standard"
                    value={targetStandard}
                    label="Target Standard"
                    onChange={(e) => onTargetSchemaChange(e.target.value)}>

                    { targetStandardModels && 
                        targetStandardModels.map(templateModels => {
                        return (
                            <MenuItem key={templateModels.API_KEY} value={templateModels.API_KEY}>{templateModels.DISPLAY_NAME}</MenuItem>
                        )
                        })
                    }
                    </Select>
                </FormControl>

                <Button sx={{ width: "20rem" }} variant="contained" onClick={showTransformedData}>
                    See Results
                </Button>
        </Box>

        <Box>
            { transformedData &&
                <div style={{"width": "100%"}}>
                    <RenderTreeTable data={transformedData} showRules={false}
                        heading={"Results"} subHeading={""}
                        tableHeader1={"Key"} tableHeader2={"Value"}
                        onDataChange={() => {}}
                    />
                </div>
                }
        </Box>
      </Paper>
    </>)
}

export default FinalResults;