import React, { useState, useEffect } from "react";
import "../styles/renderTreeTable.css";

const RenderTreeTable = ({ data, showRules, heading, subHeading, tableHeader1, tableHeader2, onDataChange }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    // Synchronize tableData with the incoming data prop
    setTableData(data);
  }, [data]);

  useEffect(() => {
    onDataChange(tableData);
  }, [tableData])

  useEffect(() => {
    // Expand all rows by default
    const allExpanded = {};
    const expandAll = (obj, path = "") => {
      Object.keys(obj).forEach((key) => {
        const currentPath = path ? `${path}.${key}` : key;
        allExpanded[currentPath] = true;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          expandAll(obj[key], currentPath);
        }
      });
    };
    expandAll(data);
    setExpandedRows(allExpanded);
  }, [data]);

  const toggleRow = (key) => {
    setExpandedRows((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleInputChange = (path, value) => {
    setTableData((prevData) => {
      // Deep clone the object to avoid modifying read-only properties
      const deepClone = (obj) => {
        return JSON.parse(JSON.stringify(obj));
      };
  
      const newData = deepClone(prevData);
      let current = newData;
      const keys = path.split(".");
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
          current[keys[i]] = {}; // Ensure the path exists
        }
        current = current[keys[i]];
      }
      const lastKey = keys[keys.length - 1];
      current[lastKey] = value;
  
      return newData;
    });
  };

  const renderTreeView = (obj, path = "", level = 0) => {
    return Object.entries(obj).map(([key, value], index) => {
      const isNumberKey = !isNaN(key);
      const parentName = path.split(".").pop();
      const displayName = isNumberKey
        ? `${parentName} Item ${parseInt(key) + 1}`
        : key;

      const currentPath = path ? `${path}.${key}` : key;
      const isExpandable = typeof value === "object" && value !== null;
      const isExpanded = expandedRows[currentPath];

      return (
        <React.Fragment key={currentPath}>
          <tr>
            <td className="element-column">
              <div style={{ paddingLeft: `${level * 20}px` }}>
                {isExpandable && (
                  <span
                    onClick={() => toggleRow(currentPath)}
                    className="toggle-icon"
                  >
                    {isExpanded ? "▼" : "▶"}
                  </span>
                )}
                <span className={isExpandable ? "expandable" : ""}>
                  {displayName}
                </span>
              </div>
            </td>
            <td>
              <textarea
                // readOnly={isExpandable}
                className="cell-textarea"
                value={typeof value === 'object' ? "" : value
                  // || currentPath
                }
                onChange={(e) =>
                  handleInputChange(currentPath, e.target.value)
                }
              />
            </td>
            {showRules && <td>
              <textarea
                // readOnly={isExpandable}
                className="cell-textarea"
                value={
                  value.rule
                  || `if ${currentPath} is NOT EMPTY\nthen use ${currentPath}\nelse use NA`
                }
                onChange={(e) =>
                  handleInputChange(currentPath, e.target.value)
                }
              />
            </td>}
          </tr>
          {isExpandable && isExpanded && (
            <tr className="nested-row">
              <td colSpan="3" style={{ padding: 0 }}>
                <table className="nested-table">
                  <tbody>{renderTreeView(value, currentPath, level + 1)}</tbody>
                </table>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  };

  if (!data || Object.keys(data).length === 0) {
    return (
      <div className="custom-mapping-table">
        <h2>{heading}</h2>
        <p>{subHeading}</p>
        <div className="no-data">No data available</div>
      </div>
    );
  }

  return (
    <div className="custom-mapping-table">
      <div className="header">
        <h2>{heading}</h2>
        <p>{subHeading}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>{tableHeader1}</th>
            <th>{tableHeader2}</th>
            {showRules && <th>Rule</th>}
          </tr>
        </thead>
        <tbody>{renderTreeView(tableData)}</tbody>
      </table>
    </div>
  );
};

export default RenderTreeTable;
