import { createSlice } from "@reduxjs/toolkit";

export const customerMappingStore = createSlice({
  name: "customerMappingObjectDate",
  initialState: null,
  reducers: {
    setObjectData: (state, action) => action.payload,
    clearObjectData: () => null,
  },
});

export const { setObjectData, clearObjectData } = customerMappingStore.actions;

export const getObjectData = (state) => state.customerMappingObjectDate;

export default customerMappingStore.reducer;
