import { configureStore } from "@reduxjs/toolkit";
import schema from "./schema";
import conversionStandard from "./conversionStandard";
import stepCount from "./stepCount";
import customerMappingStore from "./customMappingStore";
import targetSchemaReducer from "./targetSchemaSlice";
import generateMappingReducer from "./generateMappingSlice";
import storeMappingReducer from "./storeMappingSlice";
import generateRulesReducer from "./generateRulesSlice";
import updateLookupsReducer from "./updateLookupsSlice";
import storeRulesReducer from "./storeRulesSlice";
import transformEngineReducer from "./transformEngineSlice";
import fetchExistingMappingReducer from "./getExistingMappingSlice";
import fetchExistingRulesReducer from "./getExistingRulesSlice";

const reducer = {
  schema,
  conversionStandard,
  stepCount,
  customerMappingObjectDate: customerMappingStore,
  targetSchema: targetSchemaReducer,
  mapping: generateMappingReducer,
  storeMapping: storeMappingReducer,
  rules: generateRulesReducer,
  updateLookups: updateLookupsReducer,
  storeRules: storeRulesReducer,
  transformEngine: transformEngineReducer,
  existingMapping: fetchExistingMappingReducer,
  existingRules: fetchExistingRulesReducer
};

export const store = configureStore({
  reducer,
});
