import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const storeMappingJson = createAsyncThunk(
  'mapping/storeMapping',
  async ({ clientApiKey, targetStandard, requestBody }, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/mapping/client/${clientApiKey}/model/${targetStandard}`,
        {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        throw new Error('An unexpected error occured while storing the mapping');
      }
      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const storeMappingSlice = createSlice({
  name: 'storeMapping',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeMappingJson.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(storeMappingJson.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(storeMappingJson.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default storeMappingSlice.reducer;
