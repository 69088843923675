import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const fetchExistingMapping = createAsyncThunk(
  'existingMapping/fetchExistingMapping',
  async ({ clientApiKey, targetStandard }, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/mapping/client/${clientApiKey}/model/${targetStandard}`);
      if (!response.ok) {
        throw new Error('An unexpected error occured while fetching the existing mapping json');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const fetchExistingMappingSlice = createSlice({
  name: 'existingMapping',
  initialState: {
    mappingJson: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingMapping.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExistingMapping.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mappingJson = action.payload;
      })
      .addCase(fetchExistingMapping.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default fetchExistingMappingSlice.reducer;
