import React
  // {useState, useEffect }
  from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStepCount, incrementStep, decrementStep } from "../store/stepCount";
import StepIndicator from "./StepIndicator";
import { Box, Button, Paper } from "@mui/material";
import { sampleFHIRR4PatientLookup } from "./SampleTargetJson";
import RenderTreeTable from "./RenderTreeTable";
import { setNewLookup } from "../store/generateMappingSlice";
import CustomNavbar from "./CustomNavbar";
//import CircularProgress from "@mui/material/CircularProgress";

function ReviewAndConfirmLookup() {
  const currentStep = useSelector(getStepCount);

  const targetlookupJson = useSelector((state) => state?.mapping?.lookupJson);
  const dispatch = useDispatch();

  function handleBack() {
    dispatch(decrementStep());
  }

  function handleNext() {
    dispatch(incrementStep());
  }

  const handleDataChange = (newData) => {
    dispatch(setNewLookup(newData));
  };

  return (
    <>
      <CustomNavbar />
      <Paper elevation={1} sx={{ p: 3, maxWidth: "75vw", mx: "auto" }}>
        <StepIndicator activeStep={currentStep} />
        <div>
          <RenderTreeTable data={targetlookupJson} showRules={false}
            heading={"Confirm"} subHeading={"Review lookup information"}
            tableHeader1={"Fields"} tableHeader2={"Transformation Logic"}
            onDataChange={handleDataChange} 
          />
        </div>


        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end",paddingTop: '20px'  }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default ReviewAndConfirmLookup;
