import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const fetchTargetSchema = createAsyncThunk(
  'targetSchema/fetchTargetSchema',
  async ({ sourceSchema, targetStandard }, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/schema/${sourceSchema?.toUpperCase()}/${targetStandard}`);
      if (!response.ok) {
        throw new Error('An unexpected error occured while fetching the target schema');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const targetSchemaSlice = createSlice({
  name: 'targetSchema',
  initialState: {
    targetSchema: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTargetSchema.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTargetSchema.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.targetSchema = action.payload;
      })
      .addCase(fetchTargetSchema.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default targetSchemaSlice.reducer;
