import './App.css';

import LandingPage from './components/LandingPage';
import { Route, Routes } from 'react-router-dom';
import FinalResults from './components/FinalResults';
import { createTheme, ThemeProvider } from '@mui/material';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#918ef4',
      contrastText: '#fff'
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} /> 
            <Route path="/results" element={<FinalResults />} />
          </Routes>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
