import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStepCount, incrementStep, decrementStep } from "../store/stepCount";
import StepIndicator from "./StepIndicator";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import { sampleFHIRR4PatientLookup } from "./SampleTargetJson";
import RenderTreeTable from "./RenderTreeTable";
import { setNewQueries } from "../store/generateMappingSlice";
import { updateLookups } from "../store/updateLookupsSlice";
import CustomNavbar from "./CustomNavbar";

function ReviewAndConfirmQueries() {
  const [loader, setLoader] = useState(false);
  const currentStep = useSelector(getStepCount);

  const dispatch = useDispatch();

  //useAPI here to get queries , temporarily hardcoded
  const targetQueriesJson = useSelector((state) => state?.mapping?.queriesJson);
  const targetLookupJson = useSelector((state) => state?.mapping?.lookupJson);

  const sourceAndTargetSchema = useSelector((state) => state.schema);

  const updateLookupsState = useSelector((state) => state?.updateLookups.state);

  useEffect(() => {
    if (updateLookupsState === "succeeded") {
      setLoader(false);
    } else if (updateLookupsState === "failed") {
      setLoader(false);
    }
  }, [updateLookupsState])

  function handleBack() {
    dispatch(decrementStep());
  }

  function handleNext() {
    dispatch(incrementStep());
  }

  const updateLookupsJson = () => {
    setLoader(true);

    const finalLookupPreferences = { ...targetLookupJson, queries: Object.values({ ...targetQueriesJson }) }

    const requestBody = {
        "idresourceconfigurations":"",
        "clientid": sourceAndTargetSchema.clientId,
        "resourcename": sourceAndTargetSchema.targetSchema,
        "lookupdetails": { ...finalLookupPreferences }
    }

    dispatch(updateLookups(requestBody));
    dispatch(incrementStep());
  }

  const handleDataChange = (newData) => {
    dispatch(setNewQueries(newData));
  };

  return (
    <>
      <CustomNavbar />
      <Paper elevation={1} sx={{ p: 3, maxWidth: "75vw", mx: "auto" }}>
        <StepIndicator activeStep={currentStep} />
        {loader && (
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to freeze the page
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <div>
          <RenderTreeTable data={targetQueriesJson} showRules={false}
            heading={"Confirm"} subHeading={"Review query information"}
            tableHeader1={"Fields"} tableHeader2={"Query"}
            onDataChange={handleDataChange} 
          />
        </div>


        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end",paddingTop: '20px'  }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={updateLookupsJson}>
            Next
          </Button>
        </Box>
      </Paper>
    </>
  );
}

export default ReviewAndConfirmQueries;
