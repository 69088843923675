import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const fetchExistingRules = createAsyncThunk(
  'existingRules/fetchExistingRules',
  async ({ clientApiKey, targetStandard }, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/rules/client/${clientApiKey}/model/${targetStandard}`);
      if (!response.ok) {
        throw new Error('An unexpected error occured while fetching the existing AI generated rules');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const fetchExistingRulesSlice = createSlice({
  name: 'existingRules',
  initialState: {
    rules: null,
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchExistingRules.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.rules = action.payload.rules;
      })
      .addCase(fetchExistingRules.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default fetchExistingRulesSlice.reducer;
