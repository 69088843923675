// Action Items

/**
 * TODO: STEPPER - 1
 * To display the Target JSON on frontend on Stepper 1 (in TextArea) from API
 *  GET http://localhost/transform/schema/DCSA/booking (TransformEngine URL)
 */
// STEPPER 1 - Config
export const GET_TEMPLATE_STANDARDS = [{
    API_KEY: "fhir",
    CLIENT_DISPLAY_SCHEME_NAME: "EHR Source",
    CLIENT_API_KEY: "1",
    MODELS: [{
        DISPLAY_NAME: "FHIR V4 - Patient",
        API_KEY: "patient"
      },
    //   {
    //     DISPLAY_NAME: "FHIR V4 - Person",
    //     API_KEY: "person"
    //   },
    //   {
    //     DISPLAY_NAME: "FHIR V4 - CarePlan",
    //     API_KEY: "careplan"
    //   },
    ]
  },
  {
    API_KEY: "dcsa",
    CLIENT_DISPLAY_SCHEME_NAME: "Ocean Carrier",
    CLIENT_API_KEY: "2",
    MODELS: [{
      DISPLAY_NAME: "DCSA - V1 - Booking",
      API_KEY: "booking"
    }]
  }
];

// STEPPER 2 - Customization Mapping

/**
 * 
 * Move the "Generate AI Button" to Top
 * 
 * By default show the Target JSON output to MApping info
 * 
 * Do POST API call on hit of "Generate AI Button"
 * Post API from 
 * http://localhost:8000/chat  (Mapping URL)
{
    "message": "Generate ${MODELS.DISPLAY_NAME} Resource",
    "target": "patient",
    "clientId": "2"
}


Display the Mapping on the UI

Allow Users to edit

Save the changes to Backend - DONE
To create API for Mapping JSON in Transform Engine 
POST http://localhost/transform/mapping/client/{CLIENT_API_KEY}/model/{MODELS.API_KEY} (TransformEngine URL)
RequestBody 
mapping_JSON


IN Backend it is stored in s3://beyond-infinity-mapping/generated/1/patient/mapping.json
*/

// STEPPER 3 - 

// Get the Lookup & QUery JSON from step 2 itself and display them here

// STEPPER 4 - 
/*

Add UI Button to add content to the table

Button to Add & remove items on table


Update the lookup:
http://localhost:8080/transform/updatelookups

{
"idresourceconfigurations":"",
"clientid": {CLIENT_API_KEY},
"resourcename": {MODELS.API_KEY},
"lookupdetails": JSON_FORMAT
}

Sample:

{
    "idresourceconfigurations": "",
    "clientid": "1",
    "resourcename": "careplan",
    "lookupdetails": {
        "version": "R4",
        "dbsource": "health_tenant_1",
        "resource": "careplan",
        "lookupreferences": {
            "tables": [
                {
                    "name": "patientcphealthconcerns",
                    "ismandatory": "1",
                    "triggergeneration": "1"
                }
            ],
            "Queries": [
                "SELECT * FROM patientcphealthconcerns where patientcphealthconcernsid=@patientcphealthconcernsid"
            ]
        }
    }
}
*/

// STEPPER 5
/*
Load AI GENERATED RULES (HTTP Streams needed?)
http://localhost:8080/mapping/chat/generate-rules (Mapping URL)
{
    "message": "Generate ${MODELS.DISPLAY_NAME} Resource",
    "target": "patient",
    "clientId": "2"
}

Allow users to change it

WRITE API IN BE
Save the changes to S3
POST http://localhost/transform/rules/client/{CLIENT_API_KEY}/model/{MODELS.API_KEY} (TransformEngine URL)
RequestBody 
DRL Content - FILE Multipart
*/

// STEPPER 6
/**
 * Check Sample Transformed Data
 */

// Button to transform data
/*
API to http://localhost/transform/transform-cdc/{MODELS.API_KEY}.json (TransformEngine URL)

Display Output from S3 based on ResponseBody

// To Update BE API to send ResponseBody
*/