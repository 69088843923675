import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {  useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetStep, setStep } from '../store/stepCount';
import MagicWandIcon from '../magic-wand.png';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: 'transparent',
    color: 'black',
    boxShadow: 'none',
}));

export default function CustomNavbar() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirectToHomePage = () => {
        dispatch(resetStep());
        navigate("/");
    }

    const redirectToResultsPage = () => {
        dispatch(resetStep());
        navigate("/results");
    }

    return (<>
         <StyledAppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left', cursor: "pointer" }}>
                    Magic Transformer <img src={MagicWandIcon} alt="Magic Wand" style={{ width: "18px" }}/>
                </Typography>
                {/* <Button color="inherit" onClick={redirectToHomePage}>
                    Home
                </Button> */}
                <Button color="inherit" onClick={redirectToResultsPage}>
                   Results
                </Button>
            </Toolbar>
        </StyledAppBar>
    </>)
}