import {
    Box,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";

function StepIndicator({ activeStep }) {
    const steps = ['Select Target Standard', 'Customise Mapping', 'Review Lookup', 'Review Query', 'Confirm Rules'];

    return (
        <Box sx={{
            bgcolor: '#E3F2FD', // Light blue background
            borderRadius: 1, // Slightly rounded corners
            p: 2, // Padding around the stepper
            mb: 3 // Margin bottom
        }}>
            <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
export default StepIndicator;