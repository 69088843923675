import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define an async thunk for fetching data
export const updateLookups = createAsyncThunk(
  'mapping/updateLookups',
  async (requestBody, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_DROOLS_ENGINE_BASE_URL}/transform/updatelookups`,
        {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        throw new Error('An unexpected error occured while updating the lookups');
      }
      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const updateLookupsSlice = createSlice({
  name: 'updateLookups',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateLookups.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateLookups.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateLookups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default updateLookupsSlice.reducer;
