import { createSlice } from '@reduxjs/toolkit';

const stepCount = createSlice({
  name: 'stepCount', // Name of the slice
  initialState: { step: -1 }, // Initial state of the slice
  reducers: {
    incrementStep: (state) => {
      state.step = state.step + 1;
    },
    decrementStep: (state) => {
      state.step = state.step - 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    resetStep: (state) => {
      state.step = -1;
    },
  }
});

export const getStepCount = ({ stepCount }) => stepCount.step;

export const { incrementStep, decrementStep, setStep, resetStep } = stepCount.actions;
export default stepCount.reducer;
