import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    CardActions,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import backgroundImage from '../landingImage.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getStepCount, incrementStep, setStep } from '../store/stepCount';

import ImportData from './ImportData';
import CustomiseMapping from './CustomiseMapping';
import ReviewAndConfirmLookup from './ReviewAndConfirmLookup';
import ReviewAndConfirmQueries from './ReviewAndConfirmQueries';
import ReviewAndConfirmRules from './ReviewAndConfirmRules';
import CustomNavbar from './CustomNavbar';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import magicWandIcon from '../magic-wand.png';
import personalDataImage from '../personal_data.svg';
import dataExtractionImage from '../data_extraction.svg';

const StyledHero = styled(Box)(({ theme }) => ({
    marginTop: "3rem"
}));

function LandingPage() {

    const currentStep = useSelector(getStepCount);
    const dispatch = useDispatch();

    const renderSwitch = (currentStep) => {
      switch (currentStep) {
        case 0:
          return <ImportData />;
        case 1:
          return <CustomiseMapping />;
        case 2:
          return <ReviewAndConfirmLookup />;
        case 3:
          return <ReviewAndConfirmQueries />;
        case 4:
          return <ReviewAndConfirmRules />;
        default:
          return <ImportData />;
      }
    };

    const handleButtonClick = () => {
        dispatch(setStep(0));
    };

    return (
        <>
        { currentStep < 0 ? 
            <div>
                <CustomNavbar />

                <StyledHero>
                    <Typography variant="h3" component="h3" gutterBottom>
                        Welcome to Magic Transformer 
                        <img src={magicWandIcon} alt="Magic Wand" style={{ width: "30px" }}/> !
                    </Typography>
                    <Typography variant="h6" component="h6" gutterBottom>
                        Convert data from various data sources into Industry Standard Formats.
                    </Typography>

                    <Button variant="contained" onClick={() => handleButtonClick()} size="large" style={{ "margin": "2rem 0", backgroundColor: "#918ef4" }}>
                        Start Engine <PlayArrowIcon style={{ width: "30px" }} />
                    </Button>
                </StyledHero>

                <img src={dataExtractionImage} alt='Data Transformation' style={{ "margin": "1rem 0" }}/>
            </div>
            : renderSwitch(currentStep)
        }
        </>
    );
}

export default LandingPage;