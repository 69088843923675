import { createSlice } from '@reduxjs/toolkit';

const schema = createSlice({
  name: 'schema', // Name of the slice
  initialState: { // Initial state of the slice
    sourceSchema: null,
    targetSchema: null,
    clientId: null
  }, 
  reducers: {
    setSourceAndTargetSchema: (state, action) => {
      state.sourceSchema = action.payload.sourceSchema;
      state.targetSchema = action.payload.targetSchema;
      state.clientId = action.payload.clientId;
    },
  },
});

export const getSourceAndTargetSchema = ({ schema }) => schema;

export const { setSourceAndTargetSchema } = schema.actions;

export default schema.reducer;
