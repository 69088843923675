import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStepCount, incrementStep, decrementStep } from "../store/stepCount";
import { getObjectData } from "../store/customMappingStore"; // Import selector
import StepIndicator from "./StepIndicator";
import { Box, Button, Paper } from "@mui/material";
import RenderTreeTable from "./RenderTreeTable";
import CircularProgress from "@mui/material/CircularProgress";
import { sampleFHIRR4PatientMapping } from "./SampleTargetJson";
import { generateMapping, setNewLookup, setNewMapping, setNewQueries } from "../store/generateMappingSlice";
import { storeMappingJson as storeCustomMapping } from "../store/storeMappingSlice";
import { fetchExistingMapping } from "../store/getExistingMappingSlice";

import MagicWandIcon from '../magic-wand.png';
import CustomNavbar from "./CustomNavbar";

function CustomiseMapping() {
  const currentStep = useSelector(getStepCount);
  const targetObjectData = useSelector(getObjectData);
  const targetObjectJSON = JSON.parse(targetObjectData);
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState(targetObjectJSON);

  const sourceAndTargetSchema = useSelector((state) => state.schema);

  const generatedMappingJson = useSelector((state) => state?.mapping?.mappingJson);
  const existingMappingJson = useSelector((state) => state?.existingMapping?.mappingJson);

  const storeMappingState = useSelector((state) => state?.storeMapping?.status);

  useEffect(() => {
    if(generatedMappingJson) {
      setLoader(false);
      setData(generatedMappingJson);
    }
  }, [generatedMappingJson]);

  useEffect(() => {
    if(existingMappingJson) {
      const queriesJson = existingMappingJson.lookup.queries;
      const lookupJsonWithoutQueries = { ...existingMappingJson.lookup };
      delete lookupJsonWithoutQueries?.queries;

      dispatch(setNewLookup(lookupJsonWithoutQueries))
      dispatch(setNewQueries(queriesJson))

      setData(existingMappingJson.mapping);
      setLoader(false);
    }
  }, [existingMappingJson])

  useEffect(() => {
    if(storeMappingState === "succeeded"){
      setLoader(false);
      handleNext();
    } else if(storeMappingState === "failed") {
      setLoader(false); 
    }
  }, [storeMappingState]);

  const dispatch = useDispatch();

  function handleNext() {
    dispatch(incrementStep());
  }
  function handleBack() {
    dispatch(decrementStep());
  }


  const generateMappingWithAI = () => {
    setLoader(true);
    const generateMappingRequestParams = { 
      message: "Generate Mapping",
      schemaSource: sourceAndTargetSchema.sourceSchema,
      targetSchema: sourceAndTargetSchema.targetSchema,
      clientId: sourceAndTargetSchema.clientId
     };
    dispatch(generateMapping(generateMappingRequestParams));
  }

  const storeMapping = () => {
    setLoader(true);
    dispatch(storeCustomMapping({ clientApiKey: sourceAndTargetSchema.clientId, targetStandard: sourceAndTargetSchema.targetSchema, requestBody: generatedMappingJson }));
  }

  const fetchExistingMappingJson = () => {
    setLoader(true);
    dispatch(fetchExistingMapping({ clientApiKey: sourceAndTargetSchema.clientId, targetStandard: sourceAndTargetSchema.targetSchema }));
  }

  const handleDataChange = (newData) => {
    dispatch(setNewMapping(newData))
  };

  return (
    <>
      <CustomNavbar />
      <Paper elevation={1} sx={{ p: 3, maxWidth: "75vw", mx: "auto" }}>

        {loader && (
          <Box
              sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background to freeze the page
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
              }}
              >
              <CircularProgress />
          </Box>
        )}

        <StepIndicator activeStep={currentStep} />

        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", paddingTop: '20px', paddingBottom: '20px' }}>
          <Button variant="outlined" onClick={generateMappingWithAI}>
            Generate Mapping With AI &nbsp; <img src={MagicWandIcon} alt="Magic Wand" style={{ width: "18px" }}/>
          </Button>
          <Button variant="outlined" onClick={fetchExistingMappingJson}>
            Retrieve AI Generated Mapping &nbsp; 
          </Button>
        </Box>

        <div>
          <RenderTreeTable data={data} showRules={false}
            heading={"Customise Mapping"} subHeading={"Make changes to the mapping of columns."}
            tableHeader1={"Element"} tableHeader2={"Mapping Information"}
            onDataChange={handleDataChange} 
          />
        </div>


        <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end", paddingTop: '20px' }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={storeMapping}>
            Next
          </Button>
          </Box>
        </Paper>
    </>
  );
}

export default CustomiseMapping;
